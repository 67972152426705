<template>

<app-wrapper class="news">

	<app-wrapper :content="true">

		<h2>{{ $state.page.news_title }}</h2>

		<div class="news-cards">
		
			<app-card v-for="(item, index) in $state.page.news_items" :item="item" :key="index" />

		</div>

		<app-more :to="{name: 'News'}" :text="$state.page.news_button" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.news {
	padding: 64px 0px;
}

.is-mobile .news {
	padding: 40px 0px;
}

.news-cards {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
	margin-bottom: 64px;
}

.is-tablet .news-cards {
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.is-mobile .news-cards {
	grid-template-columns: 1fr;
	margin-bottom: 40px;
}

.is-tablet .news-cards >>> .card:nth-child(3),
.is-mobile .news-cards >>> .card:nth-child(3) {
	display: none;
}

.is-mobile .news-cards >>> .card:nth-child(2) {
	display: none;
}

</style>
